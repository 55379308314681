import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomReportService } from 'app/modules/jobs/customreport/service/customreportservice';
import { Regex } from 'app/shared/utils/regex';
import { CustomReportParameterTypes } from '../../model/ReportEnum';

@Component({
  selector: 'falaina-report-custom-filter-field',
  templateUrl: './report-custom-filter-field.component.html',

})
export class ReportCustomFilterFieldComponent implements OnInit {
  @Input() isAdmin=false;
  bottomNameControl: boolean;

  constructor(private formBuilder: FormBuilder,private customreportservice:CustomReportService) { }
  isNew:boolean =true;
  enableList:boolean =true
  showMessage: boolean = false;
  messageType: string = "";
  messageText: string = "";
  @Input()
  parameters: any[] = [];
  @Input()
  filterObj: { Key: any, Operator: any, Value: any }[] = [];
  @Input() filterId=0
  @Input() filterName=""
  operators = {
    column: [{ key: "EqualTo", value: "EqualTo" },
    { key: "NotEqual", value: "NotEqual" },
    { key: "GreaterThan", value: "GreaterThan" },
    { key: "LessThan", value: "LessThan" },
    { key: "In", value: "In" },
    { key: "Like", value: "Like" }],
    value: [{ key: "EqualTo", value: "EqualTo" }]
  }




  ngOnInit(): void {
      this.filterId==0?this.isNew=true:this.isNew=false;
      this.generateForm();
     
  }
  closeMessageBox() {
    this.showMessage = false;
    this.messageType = "";
    this.messageText = "";
  }
  generateForm() {
      let save = !this.isNew
    this.filterForm= this.formBuilder.group({
      params: this.formBuilder.array([]),
      save:this.formBuilder.control(save),
      name:this.formBuilder.control(this.filterName),
      id:this.formBuilder.control(this.filterId),
   
  })
    this.parameters.forEach(x => {

      this.addParamDetails(x)
    })
  }
  filterForm :FormGroup;
  newParams(data) {
    var oldData = this.filterObj.filter(x => x.Key == data?.ParameterName);
    var validators: ValidatorFn[] = []
    validators.push(Validators.required)
    if (data.ParameterType == 2 && (this.isNullOrEmpty( data.DefaultValue))) {
     
      data.DefaultValue = new Date();
    }
    else if (data.ParameterType == CustomReportParameterTypes.Bit) {
      validators.push(Validators.pattern(Regex.NumericZeroAndOne(true)));

    }
    else if (data.ParameterType == CustomReportParameterTypes.Long) {
      validators.push(Validators.pattern(Regex.NumericFromZero(true)));
    }
  
   
      if (oldData.length > 0 ) {
      
          if((data.ParameterType == CustomReportParameterTypes.List||data.ParameterType == CustomReportParameterTypes.ListText) )
          {
              this.enableList=false
              this.getDefaultDropDownVal(oldData[0]?.Value,data)
          }
         
            return this.formBuilder.group({
              Key: [oldData[0]?.Key],
              Operator: [oldData[0]?.Operator],
              Value: [oldData[0]?.Value, validators],
              DataType:data?.ParameterType
            })
  
    
     
      }
      else {
        return this.formBuilder.group({
          Key: [data?.ParameterName],
          Operator: ["EqualTo"],
          Value: data.ParameterType !=5 ? [data.DefaultValue, validators] : [],
          DataType:data?.ParameterType
        })
      }
    
  

  


  }
  get paramDetails(): FormArray {
    return this.filterForm.get("params") as FormArray
  }
  addParamDetails(data) {
    this.paramDetails.push(this.newParams(data));
  }
  @Output()
  submitEvent: EventEmitter<any> = new EventEmitter();
  onSubmit() {
    this.filterForm.markAllAsTouched()
    if (this.filterForm.valid)
      this.submitEvent.emit(this.filterForm.value)
    else {
      this.showMessage=true;
      this.messageText="Invalid inputs or check if all mandatory field(s) are filled";
      this.messageType="error";
    }
  }
  
  isNullOrEmpty(text) {
    if (text == null || text == "" || text == undefined) {
      return true
    }
    else {
      return false
    }

   
} 

//#region  Auto complete 
handleFilter(event,param)
{    
  if (event.length < 1 && event != "*")
  {
    return
  }
    
    param.option = [];
  this.getDropDownValue(event,param)
  
}
getDropDownValue(searchString,param)
  {
    if(!(param.DefaultValue != null && param.DefaultValue != undefined))
    return ;       

    this.customreportservice.autoCompleteService(param.DefaultValue,searchString).subscribe(x=>{ 
      delete x["$type"]

      if(param.ParameterType=="5")
      {
        Object.keys(x).map(key=>{
          param.option.push({
        
              text:x[key],
              value:String(key)
          })
        })
      }
      else if (param.ParameterType=="6"){
        Object.keys(x).map(key=>{
          param.option.push({
              text:x[key],
              value:x[key]
          })
        })
      }
          
            
     })        
  }

  getDefaultDropDownVal(value, param) {
    if(value?.toString().length >= 1)
    {  param.option = [];
      this.getEditDropDownValue('*',value,param);
    }   
    else
    this.enableList=true;
   
  }
  getEditDropDownValue(searchString,value,param)
  {
    if(!(param.DefaultValue != null && param.DefaultValue != undefined))
    return ;
  
    this.customreportservice.autoCompleteService(param.DefaultValue,searchString).subscribe(x=>{
      delete x["$type"]
      if(param.ParameterType==CustomReportParameterTypes.ListText)
      {
          var key = this.getKeyByValue(x,value)
          if(key?.length >= 1)
          {
           param.option.push({
              text:String(value),
              value:String(value)
            })
           // this.input.dropDownValue=String(this.input.Value)
          }      
      }
      else
      {
        param.option.push({
          text:x[value],
          value:String(value)
        })
        //this.input.dropDownValue=x[this.input.Value]
      }
           
            setTimeout(() => {
              this.enableList=true;
            }, 500);
        
        })        
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }
  toggleChange(e)
  {
    if(this.isNew)
    {
      this.bottomNameControl=e;   
      e==true?this.filterForm.get('name').setValidators(Validators.required):this.filterForm.get('name').clearValidators(); 
      this.filterForm.get('name').updateValueAndValidity();
    }
    else
    { this.bottomNameControl=false;
      e==true?this.filterForm.get('name').setValidators(Validators.required):this.filterForm.get('name').clearValidators();
      this.filterForm.get('name').updateValueAndValidity();
    }
   
  }
//#endregion
selectionChange(e,text)
{
  
}
}
