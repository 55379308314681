import * as CryptoJS from 'crypto-js';
export class FalainaLocalStorage  
{  
   
    static localStorageConstructor: any="oTJFUYS4uHP6T3preLkbog2cMhH64ZnJ";
    static localStorageData: any={};
   
    public static clear(): void {
        localStorage.clear();
    }
    public static getItem(key: string): string {
        
    
        var value = localStorage.getItem(key)
        if(!value)
        return null

        if(this.localStorageData[key] != null)
        {
            value=this.localStorageData[key];
            return value
        }
        value =CryptoJS.AES.decrypt( value,  this.localStorageConstructor.trim() ).toString(CryptoJS.enc.Utf8);
        this.localStorageData[key]=value
        return value
    }
    public static  key(index: number): string {
        return localStorage.key(index)
    }
    public static removeItem(key: string): void {
       return localStorage.removeItem(key)
    }
    public static setItem(key: string, value: string): void {
        this.localStorageData[key]=null;
       value=  CryptoJS.AES.encrypt( value.toString(), this.localStorageConstructor.trim()).toString();       
       return localStorage.setItem(key,value);
    }
    
}
