import { Export } from "@models/export/Export";
import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import jsPDF, * as jspdf from 'jspdf';
import 'jspdf-autotable';
import { FalainaUtils } from 'app/shared/utils';
import { DatePipe } from "@angular/common";
@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor( private datePipe: DatePipe) { }

  dataArr: any = []
  headArr: any = []
  exportToCSV(exportConfig: Export, fileNameWithExt) {
    this.convertDataToCSVFile(exportConfig.exportColumnHeaders, exportConfig.exportData, exportConfig.exportColumnHeaderNotToBeIncluded, fileNameWithExt);
  }
  exportToEXCEL(Arr, Name) {



    this.convertDataToExcelFile(Arr, Name)
  }
  exportToPDF(Name, headArr, dataBody, widthCell) {
    widthCell = {}
    headArr.forEach((res) => {
      res.forEach((element, i) => {

        widthCell[i] = { cellWidth: 182 / res.length }
      });


    })



    this.convertDataToPdfFile(Name, headArr, dataBody, widthCell)
  }
  convertDataToPdfFile(Name, headArr, dataBody, widthCell) {
    let paperSize = "a2";
    if(headArr[0].length >= 0 && headArr[0].length < 4)
    {
      paperSize = "a4";
    }
    else if(headArr[0].length >= 4 && headArr[0].length < 12)
    {
      paperSize = "a2";
    }
    else if(headArr[0].length >= 12 && headArr[0].length < 18)
    {
      paperSize = "a1";
    }
    else if(headArr[0].length >= 18)
    {
      paperSize = "a0";
    }
    setTimeout(() => {
      var doc = new jsPDF(headArr[0].length >= 18 ? 'l' : 'p', 'mm', paperSize);
      doc.setFontSize(12);
      doc.text(Name, 11, 8);
      doc.setFontSize(11);
      doc.setTextColor(100);
      (doc as any).autoTable({
        head: headArr,
        body: dataBody,
        theme: 'grid',
        columnStyles: 
        {
          columnwidth : widthCell
        },
        tableLineColor: [189, 195, 199],
        headStyles: { fillColor: [230, 230, 230], textColor: [0, 0, 0] },
        tableLineWidth: 0.75,
        didDrawCell: data => {
          data.column.index
        }
      })
      doc.save(Name);
    }, 500);
  }
  convertDataToExcelFile(Arr, Name) {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(Arr);
    XLSX.utils.book_append_sheet(wb, ws, 'ExportResult');
    XLSX.writeFile(wb, Name);
  }
  convertDataToCSVFile(headerColumns: any[], data: any, headerColumnsIgnored: any[], fileName: string) {
    let csvArray: any;
    const replacer = (key, value) => value === null ? "" : (value.toString().indexOf('"') > 0 ? value.replace(/"/g, " ") : value); // specify how you want to handle null values here
    if (data.length > 0) {
      const header_original = Object.keys(data[0]).filter(function (item) {
        return headerColumnsIgnored.indexOf(item) === -1
      });
      var header_show = header_original.map(function (value: string, index: number) {
        return headerColumns.filter(function (item) { return item.field === value })[0];
      });
     let _show=[]
      header_show.forEach(x=>{

          if(x)
          {
           let index= headerColumns.findIndex(i=>{return i.field == x.field})
            if(index != -1)
            x["order"] = index
            
            _show.push(x);
          }
      })
      
      header_show=_show.sort((a, b) => a.order - b.order)
      header_show = header_show.filter(function (element) { 
        return element !== undefined;
      }).map(x => x.field);
      csvArray = this.convertToCSV(data, header_show,headerColumns);
    }
    else {

    }
    var a = document.createElement("a");
    var blob = new Blob([csvArray], { type: "text" }),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  convertToCSV(objArray, headerList, headerColumns) {
    let itemToRemove;
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";
    let checkNull='';
    let newline = '\n';
    for (let index in headerList) {
      headerColumns.forEach(element => {
        if (element.field == headerList[index]) {
          row += element.title + ",";
        }
      });
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line =  "";
      for (let index in headerList) {
        let head = headerList[index]; 
        var value = array[i][head];      
          if(typeof array[i][head] == "boolean")
          checkNull=array[i][head];
          if(this.isDate(value))
          {
            value = FalainaUtils.ConvertToLocalTimeZone(value,this.datePipe)
          }
          if (value == null)
          checkNull = '';
          else
         {
          value=value.toString().replaceAll(newline.toString() ,'');
          checkNull=value.toString().replaceAll(',','');
         }
         
 
        line += "," + checkNull;
      }
      line = line.toString().substring(1)
      str += line + "\r\n";
    }
    
    return str;
  }

isDate(data : string)
{
  data = data?.toString();
  if(!data)
  {
    return false;
  }
  
    if(data.includes(':') && data.includes('T') && data.includes('-') && data.includes("."))
    {
      let date : Date = new Date(data);
       var month = date.getMonth();
       if(!isNaN(month))
       {
         return true
       }
       else 
       return false;
    }
    else
    return false;
}

}

