export enum CustomReportParameterTypes {
    varchar = 1,
    DateTime = 2,
    Long = 3,
    Bit = 4,
    List = 5,
    ListText = 6
}

export enum CustomReportParameterVariant {
    Column = 0,
    Value = 1,

}