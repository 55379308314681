import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { authServiceDetail } from 'app/core/authentication/auth.service';
import { ControlTypeEnum, ValidatorEnum } from 'app/modules/dynamic-crud/models/formFieldEnum';
import { forkJoin } from 'rxjs';
import { FilterServiceService } from '../filter-service.service';

import { FilterExpression, FilterValues } from '../model/FilterExpression';
import { FilterForm, FilterFormField } from '../model/formField';
import { data } from './fakeData';

@Component({
  selector: 'falaina-custom-filter',
  templateUrl: './custom-filter.component.html',
  styleUrls: ['./custom-filter.component.css']
})
export class CustomFilterComponent implements OnInit {

  filterIconClass='ms-Icon--Filter'
 @Input()
 url:string="";

  @Output()
  customFilterPopupSubmit: EventEmitter<any> = new EventEmitter();
  showPopup:boolean=false;
  FormFields:Array<FilterFormField>=[];
  FilterForm: FormGroup;
  //FilterValues:Array<FilterValues>=[];
  customFilterText: string="" ;
  FilterId: any=0;
  PageId: any;
  pageUrl:any;
  customFilterData: Array<any> = [];
  allFilters: any[];
  enableEdit: any=false;
 
  isFieldReady=false

  isReport: boolean=false;

  constructor(private router :Router , private filterService:FilterServiceService) { }

  ngOnInit(): void {
  
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["url"].currentValue) {
      this.isFieldReady=false;
      this.router.url.includes("customreportviewer")?  this.isReport=true: this.isReport=false;   
      this.pageUrl=this.router.url.substring(1);
      this.getFilterDatas(this.pageUrl);
      this.getFilterFields(this.pageUrl);     
    }
  }
  
  getFilterFields(pageurl: any) {
  this.filterService.getPageDefinitionByPageId(pageurl).subscribe(x=>{
    if(x!=null)
    {
      this.FormFields=new FilterForm(JSON.parse(x.JsonSchema)).FormFields   
      this.PageId=x.PageId 
      this.isFieldReady=true;
     
    }

  })
  }
  getFilterDatas(pageUrl: any) {
    this.customFilterData=[];
    
   this.filterService.getAllFilterName(pageUrl).subscribe((x:any[])=>{
     let customFilter=[];
     let StandardFilter=[];
     this.addDefaultDropDownData()
      x.forEach(y=>{
        y.FilterJsonData= JSON.parse(y.FilterJsonData)
        if(!y.IsDefault)
        { let f={
          text:y.FilterName,
          icon:"  f_margin_right_21 ",
          data:y,
          id:y.Id
        }
          customFilter.push(f);
        }        
        else
        {
          let f={
            text:y.FilterName,
            icon:"  f_margin_right_21",
            data:y,
            id:y.Id
          }
          StandardFilter.push(f);
        } })
      if(customFilter.length>0)
      {
        this.customFilterData.push(
          {
            category:"Custom Filter"
          }
        )
        this.customFilterData.push(...customFilter)
      }
      if(StandardFilter.length>0)
      {
        this.customFilterData.push(
          {
            category:"Standard Filter"
          }
        )
        this.customFilterData.push(...StandardFilter)
      }
      this.applyIcons(this.customFilterText)

   })
  }
  EditFilter(e)
  {
   let FieldName=this.customFilterText;
   let index=this.customFilterData.findIndex(x=>{return x.text==FieldName});
   if(index==-1)
   return;
   
   let filterData=this.customFilterData[index].data;
   let editId=this.customFilterData[index].id
   this.FilterId=editId;
    if(filterData.FilterJsonData.FilterValues.length ==0)
    {
      this.FilterForm=this.toFormGroup(this.FormFields,FieldName,editId,true)
    }
    else
    {
      this.updateFilterField(filterData.FilterJsonData.FilterValues);    
     
      this.FilterForm=this.toFormGroup(this.FormFields,FieldName,editId)
    }
 
   this.showPopup=true;
  }

  addDefaultDropDownData() {
    this.customFilterData=[{
      text: "New filter",
      icon: "ms-Icon ms-Icon--Add f_font_size_16 f_margin_right_6 f_primarycolor",
      id:"0"
     
    }, {
      text: "Clear filter", 
      icon: "ms-Icon ms-Icon--ClearFilter f_font_size_16 f_margin_right_6 f_primarycolor"
    },]
  }

 


  
  onSelectCustomFilter(event) {
    let url =  this.pageUrl;
 
    // if(this.FormFields.length==0)
    // {
    //   this.emitFilterAction(event)
    // }
    if(event.text=="New filter")
    {
      this.newFilter();
    }
    else if(event.text=="Clear filter")
    {
      this.clearFilter();
    }
    else if(event.text)
    {
      let data=event.data
      this.enableEdit= !data.IsDefault
      this.emitPopupSubmit(data.FilterName,data.FilterJsonData.FilterValues)
    }
    
    
   
  }
  newFilter() {
    
  
      this.FilterForm=this.toFormGroup(this.FormFields)
      this.showPopup=true;
     }
  clearFilter()
  {
  
    this.enableEdit= false    
    this.showPopup=false;   
    this.emitPopupSubmit("",[])
  }
  closeBtnClick(e)
  {   this.closeMessageBox();
    this.showPopup=false;
  } 
  emitPopupSubmit(name,filterExxprs)
  {
   
    this.applyIcons(name)

      var htmlElem =  document.getElementById("filter");
      htmlElem.innerHTML=name;
      
    this.customFilterText=name
    this.customFilterPopupSubmit.emit(filterExxprs);
  }
  applyIcons(name) {
    this.filterIconClass="ms-Icon--Filter"
    let index1= this.customFilterData.findIndex(x=>{return x?.icon=="ms-Icon ms-Icon--CheckMark f_font_size_16 f_margin_right_6 f_primarycolor"})
    if(index1 !=-1)
    this.customFilterData[index1].icon=" f_margin_right_21"
    if(name)
    {
      this.filterIconClass="ms-Icon--FilterSolid"
      let index =this.customFilterData.findIndex(x=>{return x.text==name})
      if(index != -1)
      this.customFilterData[index].icon="ms-Icon ms-Icon--CheckMark f_font_size_16 f_margin_right_6 f_primarycolor"
     
    }
  

  }

  

  getFilterFieldsAndValue(url,event)
  {
    
    // const arr = [this.filterService.getPageDefinitionByPageId(url),this.filterService.getPageFilterDataByPageId(url)];
    // let multiCall = forkJoin(arr);
    //     multiCall.subscribe(data=>{    
    //      if(data[0] !=null)
    //      {
    //       this.FormFields=new FilterForm(JSON.parse(data[0].JsonSchema)).FormFields
    //       this.PageId=data[0].PageId  
    //      }             
    //     if(data[1] !=null) 
    //      {
          
    //       this.FilterExprs=data[1].FilterJsonData?JSON.parse(data[1].FilterJsonData) :[]       
    //       this.FilterId=data[1].Id;
    //       this.updateFilterField(this.FilterExprs)
    //      }  
    
    //      if(this.FormFields.length>0)
    //     {
    //       this.FilterForm=this.toFormGroup(this.FormFields)
    //       this.showPopup=true; 
    //       return;
    //     }  

    //     else
    //     {
    //       this.emitFilterAction(event)
    //     }
     
     
    //     })
  }
  updateFilterField(FilterExprs: FilterValues[]) {
    FilterExprs.forEach(x=>{
      let index = this.FormFields.findIndex(i=>{
        return i.key==x.Key
      })
      if(index != -1)
      {
        this.FormFields[index].value=x.Value;
        this.FormFields[index].operator=x.Operator;
      }
    })
  }
  // emitFilterAction(event)
  // {
  //   if (!(event.text == "New filter" || event.text == "Clear filter"))
  //   this.customFilterText = event.text;
  // else
  //   this.customFilterText = "Filter"
  // this.customFilterAction.emit(event.text)
  // }

  SavePopup()
  { this.closeMessageBox()
    this.FilterForm.markAllAsTouched()
    if(this.FilterForm.invalid)
    return;
    var formValue = this.FilterForm.value;
    this.filterService.doesExist(this.pageUrl,formValue.FilterName,formValue.Id).subscribe(x=>{
      if(x)
      {
        this.showMessage=true;
        this.messageText="FieldName already exist"
        this.messageType="error"
        return;
      }
     
      let FilterValues=[];  
      this.FormFields.forEach(x=>{
    
        var obj = {
          Key:x.key,
          Value:formValue[x.key],
          Operator:x.operator
        }
        if(obj.Operator != "NoFilter")
        FilterValues.push(obj);     
      })
      let data =<FilterExpression>{
     
        FilterValues:FilterValues.length>0?FilterValues:[],
      }
      this.enableEdit=true;
      this.saveFilterData(data,formValue.Id,formValue.FilterName)
    
      
    })
    
  }
  saveFilterData(FilterExprs:FilterExpression,id,name) {
    let data={
      Id:id,
      FilterJsonData:JSON.stringify(FilterExprs),
      FilterName:name,
      PageId:this.PageId,
      IsDefault:false,
      UserId:+authServiceDetail.LoggedinUserId
    } 
    this.filterService.saveFilterData(data).subscribe(x=>{
      this.FilterForm=this.toFormGroup(this.FormFields);
      this.getFilterDatas(this.pageUrl);
      this.showPopup=false;      
      this.emitPopupSubmit(name,FilterExprs.FilterValues)
    })
  }
  //#region  FormGroup
  toFormGroup(inputs: FilterFormField[],filterName="",id=0,setDefault=false): FormGroup {

    inputs=inputs.sort((a, b) => a.order - b.order);
    const group: any = {};
    inputs.forEach(input => {
      let validator: ValidatorFn[] = input.required ? [Validators.required] : [];
    for(let i:number=0;i<input?.validator?.length;i++)
    {
      switch (input.validator[i].key) {
          case ValidatorEnum.Email:
          validator.push(Validators.email);
          break;
          case ValidatorEnum.MinLength:
          validator.push(Validators.minLength(input.validator[i].value));
          break;
          case ValidatorEnum.MaxLength:
          validator.push(Validators.maxLength(input.validator[i].value));
          break;
          case ValidatorEnum.Pattern:
            validator.push(Validators.pattern(input.validator[i].value));
            break;
          default:
          break;
  }
    }
    if(id==0 || setDefault)
    {
      if(input.controlType== ControlTypeEnum.Checkbox)
      {
        group[input.key] = validator.length > 0 ? new FormControl(input.defaultValue, validator)
        : new FormControl(input.defaultValue );
        input.operator="NoFilter"
      }
      else
      {
        group[input.key] = validator.length > 0 ? new FormControl(input.defaultValue || '', validator)
        : new FormControl(input.defaultValue || '');
        input.operator="NoFilter"
      }
    
     
    }
    else
    {  if(input.controlType== ControlTypeEnum.Checkbox)
      {
        group[input.key] = validator.length > 0 ? new FormControl(input.value, validator)
        : new FormControl(input.value );
      }
      else
      {
        group[input.key] = validator.length > 0 ? new FormControl(input.value || '', validator)
        : new FormControl(input.value || '');
      }
    
    }
    
    });
    group["FilterName"]= new FormControl(filterName,[Validators.required,Validators.pattern("^[A-Za-z0-9 ]+$")])
    group["Id"]= new FormControl(id)
    return new FormGroup(group);
  }
  delete()
  {
    this.filterService.Delete(this.FilterId).subscribe(x=>{
     this.clearFilter();
      this.getFilterDatas(this.pageUrl)
    })
  }
  messageText: string;
messageType
showMessage: boolean = false
closeMessageBox() {
  this.showMessage = false;
}

  //#endregion
}
