
import XRegExp from 'xregexp';
import * as RegexData from '../regex/regex.json';
export class Regex {

    constructor() {
    }

    public static Alphanumeric(){
        var c = XRegExp(RegexData.Alphanumeric).source
        return c
    }

    public static NumericFromZero(isCommaSeprated=false){
        if(isCommaSeprated)
        {
            return XRegExp(RegexData.CommaSepratedNumericFromZero).source
        }
        var c = XRegExp(RegexData.NumericFromZero).source
        return c
    }

    public static NumericFromOne(){
        var c = XRegExp(RegexData.NumericFromOne).source
        return c
    }

    public static NumericZeroAndOne(isCommaSeprated=false){
        if(isCommaSeprated)
        {
            return XRegExp(RegexData.CommaSepratedNumericZeroAndOne).source
        }
        var c = XRegExp(RegexData.NumericZeroAndOne).source
        return c
    }

    public static Email(){
        var c = XRegExp(RegexData.Email).source
        return c
    }

    
}