import {  HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '@environment/environment';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { customHttpclient } from '@services/shared/customhttpclient';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {

  constructor(private http: customHttpclient) { }

  public runNow(customReportId,ReqUserId)
  {
    let fields = []
    fields.push({Name:"customReportId",Value:customReportId})
    fields.push({Name:"RequestedBy",Value:ReqUserId});
     let params = new HttpParams()
    return this.http.postreportapi("PresentationCustomReportData","RunNow" ,fields,params)
    .pipe(
      map(res =>
        res
      ),
      catchError(err => {
        return throwError(err);
      })
    );

  }
  public GetALLReports(pg: PageChangeEvent, searchString: any) {


    let params = new HttpParams()
      .set('skip', pg.skip.toString())
      .set('pagesize', pg.take.toString())

    return this.http.getdomainapi("CustomReport/" + pg.skip + "/" + pg.take, "", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );

  }
  public getParentMenu(searchString) {
    let params = new HttpParams()
      .set('searchString', searchString)
    return this.http.getdomainapi("TabMenu", "GetAllReportMenu", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );

  }

  public getCustomReportById(id,checkAccess=false) {
    let params = new HttpParams()
      .set('reportId', id)
      .set('checkAccess', checkAccess.toString())

    return this.http.getdomainapi("CustomReport", "GetCustomReportById", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  getReportImageUrlByPageId(pageId) {
    let params = new HttpParams()
      .set('pageId', pageId)

    return this.http.getdomainapi("WebPage", "GetImageUrlByPageId", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  getReportPermission(pageId) {
    let params = new HttpParams()
      .set('pageId', pageId)

    return this.http.getdomainapi("PagePermission", "GetPagePermissions", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  deleteCustomReportByReportId(reportId) {
    let params = new HttpParams()
      .set('reportId', reportId)

    return this.http.getdomainapi("CustomReport", "DeleteCustomReportByRuleId", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  public saveCustomReport(customReport, icon, addDetail, addSchedule, addExport, roles = "") {
    let params = new HttpParams()
      .set('icon', icon)
      .set('addDetail', addDetail)
      .set('addSchedule', addSchedule)
      .set('addExport', addExport)
      .set('roles', roles)
    return this.http.postdomainapi("CustomReport", "SaveCustomReport", customReport, params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getReportViewerData(reportId, skip,
    take, FilterExpression, sortExpression, updatedQuery,searchString,isQueryParameterized=false) {

    let searchCriteria = {
      skip:skip,
      pagesize:take,
      searchString:searchString,
      sortExpressions:sortExpression,     
      ReportId:reportId,
      ReportFilterExpression:FilterExpression,
      IsQueryParameterized:isQueryParameterized
    }

    let params = new HttpParams()
    return this.http.postreportapi("PresentationCustomReportData", "GetReportViewerDataById", searchCriteria, params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  public getReportViewerDataForAudit(reportId, skip,
    take, FilterExpression, sortExpression, searchString) {
 
      let searchCriteria = {
        skip:skip,
        pagesize:take,
        searchString:searchString,
        sortExpressions:sortExpression,      
        ReportId:reportId,
        FilterExpression:FilterExpression,       
      }
      let params = new HttpParams()
      return this.http.postreportapi("PresentationCustomReportData", "GetReportViewerDataForAudit", searchCriteria, params)
        .pipe(
          map(res =>
            res
          ),
          catchError(err => {
            return throwError(err);
          })
        );
  }


  public SaveReportSchedule(data) {
    let params = new HttpParams()
    return this.http.postdomainapi("PresentationReportScheduler", "SaveScheduleReport", data, params)
  }
  public CheckReportName(data) {
    let params = new HttpParams()
      .set('RepName', data)
    return this.http.getdomainapi("PresentationReportScheduler", "CheckReportName",params)
  }

  public autoCompleteService(action,searchString) {
    let params = new HttpParams()
      .set('searchString', searchString)
    return this.http.getdomainapi("AutoCompleteDataService", action,params)
  }
  public GetTabmenuName(MenuId) {
    let params = new HttpParams()
      .set('MenuId', MenuId)
    return this.http.getdomainapi("CustomReport", "GetTabmenuName", params)
      .pipe(
        map(res =>
          res
        ),
        catchError(err => {
          return throwError(err);
        })
      );
  }
  public GetCustomSearch(skip, take,searchString) {
    let params = new HttpParams()
      .set('take', take)
      .set('skip', skip)
      .set('searchString', searchString)
    return this.http.getdomainapi("CustomReport", "GetCustomSearch", params)
  }
  
  public IsValidQuery(query: string) {

    let body ={
      "Query":query
    }
    let params = new HttpParams();
    return this.http.postdomainapi("PresentationStatisticsBuilder","IsValidQuery",body,params);

  }
  @Output()
  reportFilterAction: EventEmitter<any> = new EventEmitter();
  reportFilterActionSubmit(event)
  {
    this.reportFilterAction.emit(event)
  }
}
