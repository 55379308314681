import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { customHttpclient } from '@services/shared/customhttpclient';

@Injectable({
  providedIn: 'root'
})
export class FilterServiceService {

  constructor(private httpClient:customHttpclient) {

   }

   public getPageDefinitionByPageId(pageurl:string)
   {
    let params = new HttpParams()
    .set('pageurl',pageurl);
     return this.httpClient.getdomainapi("PresentationPageFilter","GetPageDefinitionByPageId",params)
   }
   public getPageFilterDataById(id)
   {
    let params = new HttpParams()
    .set('id',id);
     return this.httpClient.getdomainapi("PresentationPageFilter","GetPageFilterDataByPageId",params)
   }
   public getAllFilterName(pageurl:string,searchString="*")
   {
    let params = new HttpParams()
    .set('pageurl',pageurl)
   
     return this.httpClient.getdomainapi("PresentationPageFilter","GetALLPageFiltersData",params)
   }
   public doesExist(pageurl:string,name:string,id)
   {
    let params = new HttpParams()
    .set('pageurl',pageurl)
    .set('name',name)
    .set('id',id)
     return this.httpClient.getdomainapi("PresentationPageFilter","DoesExists",params)
   }
   public saveFilterData(filterData)
   {
    let params = new HttpParams()
     return this.httpClient.postdomainapi("PresentationPageFilter","Save",filterData,params)
   }
   public SaveByPageUrl(filterData)
   {
    let params = new HttpParams()
     return this.httpClient.postdomainapi("PresentationPageFilter","SaveByPageUrl",filterData,params)
   }
   public Delete(id)
   {
    let params = new HttpParams()
    return this.httpClient.deletedomainapi("PresentationPageFilter",id,null,params)
   }
}
