import { Injectable } from "@angular/core";
import {  HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { environment } from "@environment/environment";
import { PresentationAccessReviewRequestParam } from "app/modules/accessgovernance/accessreview/Models/PresentationAccessReviewRequestParam";
import { customHttpclient } from "./shared/customhttpclient";
import { UserImageInfo } from "app/shared/useraccessdetail/accessdetail/models/userimageinfo";
import { UILog } from "app/core/services/UILogInfo";
import { DashboardInfo } from "app/core/services/DashboardInfo";
import { userDatePreference } from "./userdatepreferenece";
import { AnyRecord } from "dns";
import { authServiceDetail } from "../authentication/auth.service";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})

export class UserService {

  constructor(private customhttp: customHttpclient,private router: Router) {

  }
  //public PagedResults<Dto.Presentation.PresentationActiveUser> GetSubordinatesByOrganisation(long orgId, long orgHeadId, int skip, int pagesize)
  public getSubordinatesByOrganisation(skip: any, pagesize: any, userId:any, organisationId:any) {

    let params = new HttpParams().set('orgId', organisationId).set('orgHeadId', userId).set('skip', skip).set('pagesize', pagesize)
    return this.customhttp.getdomainapi("PresentationActiveUser","GetSubordinatesByOrganisation", params);

    // return this.http.get(this.myApiUrl + "PresentationActiveUser/GetSubordinatesByOrganisation?orgId=" + organisationId + "&orgHeadId=" + userId
    // + "&skip=" + skip + "&pagesize=" + pagesize).pipe(
    //   map(res => res),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getById(id: any) {
    let params = new HttpParams().set('userId', id)
    return this.customhttp.getdomainapi("PresentationActiveUser","GetByUsrId", params);

   
  }
  // public getuserById(id: any) {
  //   let params = new HttpParams();
  //   return this.customhttp.getdomainapi("PresentationActiveUser","GetByUserId", params);

   
  // }

  public getEmployeesFilter(searchString : any, skip: any, pagesize: any) {
    let params = new HttpParams().set('searchString', searchString).set('skip', skip).set('pagesize',pagesize)
    return this.customhttp.getdomainapi("PresentationActiveUser","GetAllIDMUsersBySearchString", params);
 
  }
    public getEmployees( skip: any, pagesize: any) {
    //let params = new HttpParams().set('skip', skip).set('pagesize',pagesize)
    let params = new HttpParams().set('searchString', '*').set('skip', skip).set('pagesize',pagesize)
    return this.customhttp.getdomainapi("PresentationActiveUser","GetAllIDMUsersBySearchString", params);
 
  }

  public getAllPIMUsers(searchString:any,skip: any, pagesize: any) {

    let params = new HttpParams().set('searchString',searchString).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetAllPIMUsers";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }

  public getUsersByApprovalId(searchString :string, approvalId:any,skip: any, pagesize: any) {
    let params = new HttpParams().set('searchString',searchString).set('approvalId',approvalId).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetUsersByApprovalId";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }

  
  public getEmployeesBySearchString(searchString:string, skip: any, pagesize: any, IncludeInactiveUsers:any) {


    let params = new HttpParams().set('searchString',searchString).set('skip',skip).set('pagesize',pagesize).set('IncludeInactiveUsers',IncludeInactiveUsers);
    let actionname = "GetAllIDMUsersBySearchString";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
    
  }
  public getUserByVariantType( searchString,
    variantValue,skip,pagesize,IncludeInactiveUsers) {
    let userId=authServiceDetail.LoggedinUserId.toString();    

    let params = new HttpParams()
    .set('searchString',searchString)
    .set('userId',userId)
    .set('variantValue',variantValue)
    .set('pagesize',pagesize)
    .set('skip',skip)
    .set('IncludeInactiveUsers',IncludeInactiveUsers)
    let actionname = "ProcessPagedResult";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
    
  }
  public getUserByVariantTypeAuto( searchString,
    variantValue,skip,pagesize,IncludeInactiveUsers) {
    let userId=authServiceDetail.LoggedinUserId.toString();    

    let params = new HttpParams()
    .set('searchString',searchString)
    .set('userId',userId)
    .set('variantValue',variantValue)
    .set('pagesize',pagesize)
    .set('skip',skip)
    .set('IncludeInactiveUsers',IncludeInactiveUsers)
    let actionname = "Process";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
    
  }

  public getUsersByRequest(requestId : any,searchString:string, skip: any, pagesize: any) {
    let params = new HttpParams().set('requestId',requestId).set('searchString',searchString).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetUsersByRequest";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }

  public getPAMUsersByRequest(requestId : any,searchString:string, skip: any, pagesize: any) {
    let params = new HttpParams().set('requestId',requestId).set('searchString',searchString).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetPAMUsersByRequest";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }


  public getUsersByCampaign(campaignId : any,searchString:string, skip: any, pagesize: any) {
    let params = new HttpParams().set('campaignId',campaignId).set('searchString',searchString).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetUsersByCampaign";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }

  
  public getPAMUsersByCampaign(campaignId : any,searchString:string, skip: any, pagesize: any) {
    let params = new HttpParams().set('campaignId',campaignId).set('searchString',searchString).set('skip',skip).set('pagesize',pagesize);
    let actionname = "GetPAMUsersByCampaign";
    return this.customhttp.getdomainapi("PresentationActiveUser",actionname,params);
  }

  public getHRMSFieldsByEmployeeType(employeetypeId: any) {

    let params = new HttpParams().set('EmployeeTypeId', employeetypeId)
    return this.customhttp.getdomainapi("PresentationHRMSFieldConfiguration","GetHRMSUserFieldConfigByEmpType", params);


    // return this.http.get(this.myApiUrl + "PresentationHRMSFieldConfiguration/GetHRMSUserFieldConfigByEmpType?EmployeeTypeId=" + employeetypeId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }
  public getAllEmployees() {

    let params = new HttpParams();
    return this.customhttp.getdomainapi("PresentationActiveUser","GetAllActiveUsers", params);

    // return this.http.get(this.myApiUrl + "PresentationActiveUser/GetAllActiveUsers").pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
    }
  //   public getuserdatepreferance() {

  //     let params = new HttpParams().set("userId","0")
  //     return this.customhttp.getdomainapi("PresentationUserCulturePreference","GetDefaultPreference", params);

  //     // return this.http.get(this.myApiUrl + "PresentationUserCulturePreference/GetDefaultPreference").pipe(
  //     //   map(
  //     //     res => res
  //     //   ),
  //     //   catchError(err => {
  //     //     return throwError(err);
  //     //   })
  //     // );
  // }
  public GetPreferenceByUserId(userId: any) {

    let params = new HttpParams()
    return this.customhttp.getdomainapi("PresentationUserCulturePreference", "GetPreferenceByUserId", params);
  }
    public saveUserdateprefernce( dateinfo : userDatePreference): Observable<boolean> {
      let params = new HttpParams()
      
      let actionname = ''
      // return this.customhttp.getdomainapi('PresentationUserImage', actionname, params);
      return this.customhttp.postdomainapi('PresentationUserCulturePreference', actionname,dateinfo, params);
  }

  // public SaveHRMSConfig(PresentationHRMSConfiguration: any)
  // {   
  //   let params = new HttpParams();
  //   return this.customHttp.postdomainapi("PresentationHRMSConfiguration","SaveHRMSConfig",PresentationHRMSConfiguration, params);

  //  // return this.http.post(this.baseUrl + "PresentationHRMSConfiguration/SaveHRMSConfig", PresentationHRMSConfiguration);
  // }

  public saveUserLanguagePrefernce( userinfo : userDatePreference) {
    console.log(userinfo);
    
    let params = new HttpParams()
    
    let actionname = 'SaveLanguage'
    // return this.customhttp.getdomainapi('PresentationUserImage', actionname, params);
    return this.customhttp.postdomainapi('PresentationUserCulturePreference', actionname,userinfo, params);
}
    public saveUserImage(  imageInfo : UserImageInfo): Observable<boolean> {
      let params = new HttpParams()
      
      let actionname = 'SaveUserImage'
      // return this.customhttp.getdomainapi('PresentationUserImage', actionname, params);
      return this.customhttp.postdomainapi('PresentationUserImage', actionname,imageInfo, params);
  }
  public saveUILog(  uiLog : UILog): Observable<boolean> {
 
    let params = new HttpParams()
    let actionname = 'SaveUILog'
    return this.customhttp.postdomainapi('PresentationUILog', actionname,uiLog, params);
}
public saveDashboardPrefernce(DashboardInfo : DashboardInfo[]): Observable<boolean> {
 
  let params = new HttpParams()
  let actionname = 'SaveUserDashboardPreference'
  return this.customhttp.postdomainapi('PresentationUserDashboardPreference', actionname,DashboardInfo, params);
}
public addDashboardPrefernce(DashboardInfo : DashboardInfo): Observable<boolean> {
 
  let params = new HttpParams()
  let actionname = 'AddUserDashboardPreference'
  return this.customhttp.postdomainapi('PresentationUserDashboardPreference', actionname,DashboardInfo, params);
}



  public getUserInfo(userId) {

    let params = new HttpParams();
    return this.customhttp.getdomainapi("PresentationActiveUser","GetUserDetails", params);

    // return this.http.get(this.myApiUrl + "PresentationActiveUser/GetUserDetails?userId=" + userId).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );
  }

  public getEndpointsbyUserId(userId, skip:any, pagesize:any, searchString:any, mode:any) {


    let params = new HttpParams().set('userIds',userId).set('skip',skip).set('pagesize',pagesize).set('searchString',searchString)
    .set('mode',mode);
    return this.customhttp.getdomainapi("PresentationUserAccessInfoEndpointList","GetUserAccessInfoEndpointListByUserId", params);

    // return this.http.get(this.myApiUrl + 
    //   "PresentationUserAccessInfoEndpointList/GetUserAccessInfoEndpointListByUserId?userIds="+ userId
    //   +"&skip=" +skip+"&pagesize=" +pagesize+"&searchString=" +searchString +"&mode=" +mode
      
      
    //   ).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    //);

  }

  public getEntitlementsbyUserIdEpIds(requestparam:PresentationAccessReviewRequestParam) {

    let params = new HttpParams()
    let actionname = 'GetUserAccessInfoTest'
    return this.customhttp.postdomainapi('PresentationUserAccessInfoWrapper', actionname,requestparam, params);

    // return this.http.post(this.myApiUrl + 
    //   "PresentationUserAccessInfoWrapper/GetUserAccessInfo ",
     
    //   requestparam     
      
    //   ).pipe(
    //   map(
    //     res => res
    //   ),
    //   catchError(err => {
    //     return throwError(err);
    //   })
    // );

  }

  
  public GetAccountsByUser(endpointId:any, userId:any):Observable<any> {
    let params = new HttpParams().set("endPointId", endpointId).set("userId", userId);
    return this.customhttp.getdomainapi("PresentationAccountReconcilation", "GetAccountInfoByUser",params);

  }



  public getSAandSODdataByAccounttype(requestparam:PresentationAccessReviewRequestParam) {
    let params = new HttpParams();
    return this.customhttp.postdomainapi("PresentationUserComplianceSODReport", "GetUserComplianceDetailsByEndpointAndAccounttype", requestparam, params).pipe(
      map(res =>
        res
      ),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public GetFilterActiveUser(skip: any, pagesize: any, searchString:any,sortExpression:any,filterexprs=[]) {
    let url= this.router.url.substring(1);
    let UservariantValue="UserList~" + url;
    let criteria = {
      skip:skip,
      pagesize:pagesize,
      searchString:searchString,
      sortExpressions:sortExpression,
      FilterExpressions:filterexprs,
      UservariantValue:UservariantValue
    }
    let params = new HttpParams();
    //let params = new HttpParams().set('skip',skip).set('pagesize',pagesize).set('sortExpression',sortExpression).set('searchString',searchString)
    return this.customhttp.postdomainapi("PresentationActiveUser","GetFilterActiveUser", criteria,params)
    .pipe(
      map(res =>
        res
      ),
      catchError(err => {
        return throwError(err);
      })
    );

    // return this.http.get(this.myApiUrl + "PresentationActiveUser/GetFilterActiveUser?skip=" + skip +
    //   "&pagesize=" + pagesize + "&sortExpression=" + sortExpression + "&searchString=" + searchString).pipe(
    //     map(
    //       res => res
    //     ),
    //     catchError(err => {
    //       return throwError(err);
    //     })
    //   );
  }

   //
   public getGlobalLanguages(){
    var tenentName='GLOBALLANGUAGES';
    let params = new HttpParams().set('tenentName',tenentName);
    return this.customhttp.getdomainapi("PresentationCodesTable","GetAllByTenentName",params)
  }
  public GetAllCodeByTenentName(tenentName: string) {

    let params = new HttpParams().set('tenentName',tenentName)
    return this.customhttp.getdomainapi("PresentationCodesTable","GetAllByTenentName", params);


  }
 

}
