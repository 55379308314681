import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppConfig } from "@models/config/appconfig";
import { AppConfigService } from "@services/shared/app-config-service";
import { Observable } from "rxjs";

@Component({
  selector: "falaina-message-box",
  templateUrl: "./falainamessagebox.component.html"
})
export class FalainamessageboxComponent implements OnInit {
showErrorMessageBox:boolean=false
@Input() showCloseButton:boolean = true;

@Input() messageboxType : any = "";

@Input()
messageboxText : any = "";

@Input()
messageboxrefresh : boolean=false;

IsvisibleWarning : boolean = true;
isExpanded : boolean = false;
iconClass : any = "chevronsDown";
@Input()  showexpand : boolean=false;

@Output()
closeMessageBox: EventEmitter<any> = new EventEmitter();
closedivWarning(id : any)
{
  this.messageboxType = "";
  this.closeMessageBox.emit();
}
@Output()
refreshMessageBox: EventEmitter<any> = new EventEmitter();
showrefreshevent(e:any){
  this.refreshMessageBox.emit();
}

showFullText(evt:any)
{
 
  var ele = !this.showexpand ?  evt.target.previousSibling : evt.target.previousElementSibling;

  if(this.isExpanded == false)
  {

    ele.classList.remove("overflowTxt");
    this.isExpanded = true;
    this.iconClass = "chevronsUp";
  }
  else
  {
    ele.classList.add("overflowTxt");
    this.isExpanded = false;
    this.iconClass = "chevronsDown";
  }

}

  constructor(private config : AppConfigService) {

    var a:Observable<AppConfig>= this.config.loadConfigurations();
   a.subscribe(x=>{
    this.showErrorMessageBox=x.ShowErrorMessageInUI
   })
   }
  ngOnInit(): void {
  }
}
