import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UserService } from "@services/user.service";
import { FalainaAvatarSize } from "./falainaavatarsize";

@Component({
  selector: "falaina-avatar",
  templateUrl: "./falainaavatar.component.html"
})
export class FalainaavatarComponent implements OnInit {

  @Input()

  avatarId: any;

  @Input()
  avatarShape: any = "circle";
  @Input()
  avatarInitials: any = "";
  @Input()
  avatarData: any;
  @Input()
  avatarFirstname: any;
  @Input()
  avatarSecondname: any;
  @Input()
  avatarDetail: any;
  @Input()
  avatarIcon: any = "";
  @Input()
  avatarLook: any = "flat";
  @Input()
  avatarSize: FalainaAvatarSize;
  @Input()
  avatarFill: any = "solid";
  @Input()
  avatarBorder: any = false;
  avatarClass: any = "f_profile_avatar k-avatar-bg-primary avatarClass";
  @Input()
  avatarImgSrc: any = "";
  avatarWidth: any = "";
  avatarHeight: any = "";
  @Input()
  avatarThemeColor: any = "info";
  isLoaded: boolean = false;
  @Input()

  showUserProfile: any = false;
  avatarToolTip: any = "";
  showUserPopup: boolean;
  selectedUserProfile: any;
  public GetInitial(): string {
    if (this.avatarData) {
      if (this.avatarData[this.avatarFirstname])
        this.avatarInitials = this.avatarData[this.avatarFirstname].charAt(0);
      if (this.avatarData[this.avatarSecondname])
        this.avatarInitials = this.avatarInitials + this.avatarData[this.avatarSecondname].charAt(0);
      if (!this.avatarData[this.avatarFirstname] && !this.avatarData[this.avatarSecondname] &&
        this.avatarData[this.avatarDetail]) {

        let space = this.avatarData[this.avatarDetail].indexOf(' ');
        if (space <= 0) {
          let tempInitials = this.avatarData[this.avatarDetail].replace(/[^a-zA-Z0-9 ]/g, "");
          if (tempInitials !== undefined && tempInitials !== '') {
            this.avatarInitials = tempInitials.slice(0, 2)
          } else {
            this.avatarInitials = this.avatarData[this.avatarDetail].charAt(0);
          }
        } else {
          let tempInitials = this.avatarData[this.avatarDetail].replace(/[^a-zA-Z0-9 ]/g, "");
          const avatardetailsname = tempInitials.split(' ');
          this.avatarInitials = avatardetailsname.shift().charAt(0) + avatardetailsname.pop().charAt(0);


        }
      }
    }
    return this.avatarInitials;
  }

  @Input()
  RemainingCount =0;

  constructor(private sanitizer: DomSanitizer, private userService: UserService) {
  }
  ngOnChanges(changes: SimpleChanges) {

    if (changes["avatarInitials"]) {


      if (this.avatarInitials || this.avatarImgSrc || this.avatarIcon) {
        setTimeout(() => {
          this.isLoaded = true;
        }, 500);
      }
    }
    if (changes["RemainingCount"]) {
      this.avatarInitials =changes["RemainingCount"].currentValue;
    }

    if (changes["avatarData"]) {

      let data = changes["avatarData"].currentValue;

      if (data && data[this.avatarDetail]) {
        this.avatarToolTip = data[this.avatarDetail];
      }
      this.GetInitial();
    }
    if (changes["avatarDetail"]) {
      let data = changes["avatarDetail"].currentValue;
      if (data && this.avatarData) {
        this.avatarToolTip = this.avatarData[data];
      }
      this.GetInitial();
    }
    if (changes["avatarImgSrc"]) {
      this.avatarImgSrc = changes["avatarImgSrc"].currentValue
      var base64regex = /(?:[A-Za-z0-9+\/]{4}\\n?)*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)/;

      let base64check = base64regex.test(this.avatarImgSrc);
      if (base64check) {
        if (!this.avatarImgSrc.startsWith("data:")) {

          this.avatarImgSrc = "data:image/png;base64," + this.avatarImgSrc;
        }
      }
      // else
      // {
      //   base64regex = /^(?=(.{4})*$)[A-Za-z0-9+/]*={0,2}$/;
      //   base64check = base64regex.test(this.avatarImgSrc);
      //   if (base64check) {
      //     if (!this.avatarImgSrc.startsWith("data:")) {
  
      //       this.avatarImgSrc = "data:image/png;base64," + this.avatarImgSrc;
      //     }
      //   }
      // }
      setTimeout(() => {
        this.isLoaded = true;
      }, 500);
    }
  }
  ngOnInit(): void {
    this.isLoaded = false;

    this.GetInitial();
    if (this.avatarSize == "small") {
      this.avatarWidth = "28px";
      this.avatarHeight = "28px";
      this.avatarClass = this.avatarClass + " avatarSmall";
    }

    else if (this.avatarSize == "Xsmall") {
      this.avatarWidth = "21px";
      this.avatarHeight = "21px";
      this.avatarClass = this.avatarClass + " avatarXSmall";
    }
    else if (this.avatarSize == "medium") {
      this.avatarWidth = "66px";
      this.avatarHeight = "66px";
      this.avatarClass = this.avatarClass + " avatarMedium";
    }
    else if (this.avatarSize == "large") {
      this.avatarWidth = "99px";
      this.avatarHeight = "99px";
      this.avatarClass = this.avatarClass + " avatarLarge";
    }
    else {
      this.avatarWidth = "40px";
      this.avatarHeight = "40px";
    }

    if (this.avatarInitials || this.avatarImgSrc || this.avatarIcon) {
      setTimeout(() => {
        this.isLoaded = true;
      }, 500);
    }

    if (this.avatarImgSrc) {
      var base64regex = /(?:[A-Za-z0-9+\/]{4}\\n?)*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)/;

      let base64check = base64regex.test(this.avatarImgSrc);
      if (base64check) {
        if (!this.avatarImgSrc.startsWith("data:")) {

          this.avatarImgSrc = "data:image/png;base64," + this.avatarImgSrc;
        }
      }

      // else {
      //   this.avatarImgSrc = "../assets/images/ep/" + this.avatarImgSrc;
      // }
    }


  }
  @Output() onItemClick = new EventEmitter<any>();
  onClick(e: any) {
    this.onItemClick.emit(this.avatarData);
  }
  ngafterviewinit() {
  }



}
