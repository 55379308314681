import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { authServiceDetail } from 'app/core/authentication/auth.service';
import { CustomReportService } from 'app/modules/jobs/customreport/service/customreportservice';
import { FilterServiceService } from '../filter-service.service';
import { FilterExpression } from '../model/FilterExpression';
import { ReportCustomFilterFieldComponent } from './report-custom-filter-field/report-custom-filter-field.component';

@Component({
  selector: 'falaina-report-custom-filter',
  templateUrl: './report-custom-filter.component.html',
  styleUrls: ['./report-custom-filter.component.css']
})
export class ReportCustomFilterComponent implements OnInit {
  customFilterData=[];
  customFilterText: any="";

  showPopup: boolean=false;
  filterObj: any[]=[];
  @ViewChild("dynamicform") dynamicFormInstance: ReportCustomFilterFieldComponent;
  id: any;
  filterIconClass='ms-Icon--Filter'
  customReportParams: any;
  pageUrl: any;
  enableCustomFilter: boolean;
  isFieldReady: boolean=false;
  PageId
  FilterId: any=0;
  enableEdit: any=false;
  filterName: any;
  filterClientSide: boolean=false;
  backUpClientFilter=[];
  userinfo: any;
  isadmin: boolean=false;
  constructor(private filterService:FilterServiceService,private router :Router,private activeRoute: ActivatedRoute,private customReportServ: CustomReportService) { }


  ngOnInit(): void {
    this.userinfo=authServiceDetail.LoggedinUserInfo;
  this.checkAdminAcess();
    this.isFieldReady=false
    this.pageUrl=this.router.url.substring(1);
    let length=this.pageUrl.split('/').length;
    this.getFilterDatas(this.pageUrl);
    if(length>0)
    {
      this.id=this.pageUrl.split('/')[length-1];
      this.getCustomReport(this.id);
    }
  }
  checkAdminAcess() {
    var index = (<Array<any>>this.userinfo.Roles).findIndex(x=>{return x.RoleName=="Administrator"})
    if(index!=-1)
    {
      this.isadmin=true;
      return;
    }
    this.isadmin=false;
  }
  getCustomReport(id){
    this.customReportServ.getCustomReportById(id,true).subscribe(x => {
        this.customReportParams = x.CustomReportParameters;
        //this.PageId=319;
        this.isFieldReady=true;
  })}
  getFilterDatas(pageUrl: any) {
    this.customFilterData=[];
    
   this.filterService.getAllFilterName(pageUrl).subscribe((x:any[])=>{
     let customFilter=[];
     let StandardFilter=[];
     this.addDefaultDropDownData()
      x.forEach(y=>{
        y.FilterJsonData= JSON.parse(y.FilterJsonData)
        if(!y.IsDefault)
        { let f={
          text:y.FilterName,
          icon:"  f_margin_right_21 ",
          data:y,
          id:y.Id
        }
          customFilter.push(f);
        }        
        else
        {
          let f={
            text:y.FilterName,
            icon:"  f_margin_right_21",
            data:y,
            id:y.Id
          }
          StandardFilter.push(f);
        } })
      if(customFilter.length>0)
      {
        this.customFilterData.push(
          {
            category:"Custom Filter"
          }
        )
        this.customFilterData.push(...customFilter)
      }
      if(StandardFilter.length>0)
      {
        this.customFilterData.push(
          {
            category:"Standard Filter"
          }
        )
        this.customFilterData.push(...StandardFilter)
      }
      this.applyIcons(this.customFilterText)

   })
  }
  EditFilter(e)
  {
    if(this.filterClientSide || this.backUpClientFilter.length !=0)//this to handle client side filter
    { 
      this.filterClientSide=true;
      if(this.filterObj.length==0)
      this.filterObj=this.backUpClientFilter;
      this.toGenerateForm(this.customReportParams,"",0);
      this.showPopup=true;
      return;
    }
   let FieldName=this.customFilterText;
   let index=this.customFilterData.findIndex(x=>{return x.text==FieldName});
   if(index==-1)
   return;
   
   let filterData=this.customFilterData[index].data;
   let editId=this.customFilterData[index].id
   this.FilterId=editId;
  if(filterData.FilterJsonData.FilterValues.length ==0)
  {
    this.toGenerateForm(this.customReportParams,FieldName,editId,true)
  }
  else{
    this.filterObj=filterData.FilterJsonData.FilterValues;
    this.toGenerateForm(this.customReportParams,FieldName,editId)
  }
 
   this.showPopup=true;
  }

  addDefaultDropDownData() {
    this.customFilterData=[{
      text: "New filter",
      icon: "ms-Icon ms-Icon--Add f_font_size_16 f_margin_right_6 f_primarycolor",
      id:"0"
     
    }, {
      text: "Clear filter", 
      icon: "ms-Icon ms-Icon--ClearFilter f_font_size_16 f_margin_right_6 f_primarycolor"
    },]
  }
  onSelectCustomFilter(event) {
    let url =  this.pageUrl;
 
    // if(this.FormFields.length==0)
    // {
    //   this.emitFilterAction(event)
    // }
    if(event.text=="New filter")
    {
      this.backUpClientFilter=this.filterObj;
      this.filterClientSide=false;
      this.newFilter();
    }
    else if(event.text=="Clear filter")
    {this.filterClientSide=false;
      this.clearFilter();
    }
    else if(event.text)
    {this.filterClientSide=false;
      let data=event.data
      this.enableEdit= !data.IsDefault
      this.emitPopupSubmit(data.FilterName,data.FilterJsonData.FilterValues)
    }
  }
  
  // clearFilterObj()
  // {
  //   this.bckUpFilterObj=this.filterObj
  //   this.filterObj=[];
    
  // }
  newFilter()
  {
    
    this.toGenerateForm(this.customReportParams)
    this.showPopup = true;
  }
 
  clearFilter() {
    this.enableEdit= false    
    this.showPopup=false;   
    this.filterObj=[];
    this.backUpClientFilter=[];
    this.emitPopupSubmit("",[])

  }
  closeBtnClick(e)
  {   this.closeMessageBox();
    this.showPopup=false;
  } 
  
  emitPopupSubmit(name,filterExxprs)
  {
   
    this.applyIcons(name)

      var htmlElem =  document.getElementById("Reportfilter");
      htmlElem.innerHTML=name;
      
    this.customFilterText=name
    this.customReportServ.reportFilterActionSubmit(filterExxprs);
  }
  applyIcons(name) {
    this.filterIconClass="ms-Icon--Filter"
    let index1= this.customFilterData.findIndex(x=>{return x?.icon=="ms-Icon ms-Icon--CheckMark f_font_size_16 f_margin_right_6 f_primarycolor"})
    if(index1 !=-1)
    this.customFilterData[index1].icon=" f_margin_right_21"
    if(name)
    {
      this.filterIconClass="ms-Icon--FilterSolid"
      let index =this.customFilterData.findIndex(x=>{return x.text==name})
      if(index != -1)
      this.customFilterData[index].icon="ms-Icon ms-Icon--CheckMark f_font_size_16 f_margin_right_6 f_primarycolor"
     
    }
  

  }
  
  SavePopup() {
    
    this.dynamicFormInstance.onSubmit();
  }
  submit(event) {
    this.closeMessageBox()
    var formValue = event;
    if(event?.params.length > 0)
    {
      (<Array<any>>event?.params).forEach(x=>{
       if(this.isNullOrEmpty(x.Value))
       {
         x.Value='';
       }
      })  
    }
    if(!this.isadmin)
    {
      formValue.save = false;
    }
    if(!formValue.save)
    {  
    //   let data =<FilterExpression>{
     
    //   FilterValues:formValue.params.length>0?formValue.params:[],
    // }
    //   let data1={
    //   Id:0,
    //   FilterJsonData:JSON.stringify(data),
    //   FilterName:"dummy",
    //   PageId:this.PageId,
    //   IsDefault:false,
    //   UserId:+authServiceDetail.LoggedinUserId
    // } 
    //   this.customFilterData.push({
    //     data:data1,
    //     icon:'  f_margin_right_21 ',
    //     id:0,
    //     text:'dummy'
    //   })
      this.showPopup=false;   
      this.enableEdit=true; 
      this.filterObj=formValue.params;
      this.filterClientSide=true;  
      this.backUpClientFilter=[];
      this.emitPopupSubmit("Filter",formValue.params)
      return;
    }
    if(!this.isadmin)
    {  this.showMessage=true;
      this.messageText="No authorization access please, contact your administrator"
      this.messageType="error"
      return;
    }
    this.filterService.doesExist(this.pageUrl,formValue.name,formValue.id).subscribe(x=>{
      if(x)
      {
        this.showMessage=true;
        this.messageText="FieldName already exist"
        this.messageType="error"
        return;
      }
      this.filterClientSide=false;
      let data =<FilterExpression>{
     
        FilterValues:formValue.params.length>0?formValue.params:[],
      }

      this.enableEdit=true;
      this.saveFilterData(data,formValue.id,formValue.name)
    });
    // var filterObj = event?.params;
    // this.customReportServ.reportFilterActionSubmit(filterObj);
  }
  saveFilterData(FilterExprs:FilterExpression,id,name) {
    let data={
      Id:id,
      FilterJsonData:JSON.stringify(FilterExprs),
      FilterName:name,
      PageId:0,
      pageUrl:this.pageUrl,
      IsDefault:false,
      UserId:+authServiceDetail.LoggedinUserId
    } 
    this.filterService.SaveByPageUrl(data).subscribe(x=>{
      this.backUpClientFilter=[];
      this.toGenerateForm(this.customReportParams)
      this.getFilterDatas(this.pageUrl);
      this.showPopup=false;      
      this.emitPopupSubmit(name,FilterExprs.FilterValues)
    })
  }
  toGenerateForm(params,filterName="",id=0,setDefault=false)
  {
    this.customReportParams=params;
    this.FilterId=id;
    this.filterName=filterName;
    if(setDefault || id==0 && !this.filterClientSide)
    {
      this.filterObj=[];
    }
  }
  delete()
  {
  
    if(!this.isadmin)
    {  this.showMessage=true;
      this.messageText="No authorization access please, contact your administrator"
      this.messageType="error"
      return;
    }
    this.filterService.Delete(this.FilterId).subscribe(x=>{
     this.clearFilter();
      this.getFilterDatas(this.pageUrl)
    })
  }

  messageText: string;
  messageType
  showMessage: boolean = false
  closeMessageBox() {
    this.showMessage = false;
  }
  isNullOrEmpty(text) {
    if (text == null || text == "" || text == undefined) {
      return true
    }
    else {
      return false
    }


  }
 
}
